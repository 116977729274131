import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header6 from './../Layout/Header6';
// import Footer5 from './../Layout/footer5'

import Index4Tab from './../Element/Index4Tab';
import ReviewsCarousel from './../Element/ReviewsCarousel';
import ShortAccordions from '../Pages/ShortCode/ShortAccordions'
import {VideoPopup2} from './../Element/VideoPopup';
import bnr2 from '../../images/background/bg1.png';


import {wraperBlog, wraperBox2, wraperBox3, wraperBox4,
wraperBox5, wraperBox6, wraperBox7, wraperBox8,
dlabBox, dlabBox2,} from '../Pages/ShortCode/DataFile';
//Images..
import bgimg from './../../images/main-slider/slide6.jpg';
import bg16 from './../../images/background/bg16.jpg';
import about1 from './../../images/about/pic9.jpg';
import bg1 from './../../images/background/bg1.jpg';
import gallery10 from './../../images/gallery/pic10.jpg';
import gallery11 from './../../images/gallery/pic11.jpg';
import gallery12 from './../../images/gallery/pic12.jpg';
import gallery13 from './../../images/gallery/pic13.jpg';
import gallery14 from './../../images/gallery/pic14.jpg';
import gallery15 from './../../images/gallery/pic15.jpg';
import gallery16 from './../../images/gallery/pic16.jpg';

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

class  Homepage extends Component {
    componentDidMount() {
        var i = 0;
		
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
		
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
    }
    render() {
        return (
            <>
                <Header6 />
                <div className="page-content bg-white rubik">

					<div className="home-banner" style={{backgroundImage:"url("+ bgimg +")" }}>
						<div className="home-bnr-inner">
							<div className="home-bnr-content">
								
								<h2 className="dz-title">MÖ</h2>
                                <h4 className="sub-title">Método Ö</h4>
								<div className="home-bnr-btns">
									{/* <Link to={"#"} className="site-button white btn-icon">Read more <i className="fa fa-angle-double-right"></i></Link> */}
									<VideoPopup2 />
								</div>
							</div>	
						</div>
					</div>

                    {/* <!-- contact area --> */}
                    <div className="content-block">
                    
                         <div className="   gradient testimonial-curv-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8">
                                        <div className="section-head text-center">
                                            <h2 className="text-uppercase"><span className="font-weight-300">Happy customers</span> <br /> Our Reviews</h2>
                                            <h5 className="font-weight-300 text-gray-dark">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</h5>
                                        </div>
                                        {/* <ReviewsCarousel /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonial End --> */}
                        <div className="section-full content-inner-2 tab-bx br-top">
                            <div className="container">
                                <div className="">
                                    
                                    <Index4Tab />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonial End --> */}
                       
              
                        <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
                            {/* <div className="sort-title-bx" data-name="Element Style 8">Element Style 8 <i className="fa fa-angle-double-right"></i></div>
                            */}
                            <div className="container">
                                <div className="section-content">
                                    <div className="row">
										{wraperBox8.map((item,index)=>(
											<div className="col-6 col-lg-3 col-md-6 col-sm-6">
												<div className="icon-bx-wraper">
													<div className="icon-md text-black m-b20">
														<Link to={"#"} className="icon-cell text-black">{item.icon1}</Link>
													</div>
													<div className="icon-content m-b30">
														<h5 className="dlab-tilte">{item.title1}</h5>
														<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
													</div>
												</div>
												<div className="icon-bx-wraper m-b30">
													<div className="icon-md text-black m-b20">
														<Link to={"#"} className="icon-cell text-black">{item.icon2}</Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">{item.title2}</h5>
														<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
													</div>
												</div>
											</div>
										))}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ShortAccordions/> */}
                        {/* !-----Accordian End----! */}
                        {/* <!-- Contact Us --> */}
                        <div className="section-full content-inner-2 bg-white contact-form-bx" style={{ backgroundImage: "url(" + bg16 + ")" , backgroundSize: "100%" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="text-uppercase"><span className="font-weight-300">Contact </span> us</h2>
                                </div>
                                <div className="dezPlaceAni">
                                    <div className="dzFormMsg"></div>
                                    <form method="post" className="" action="">
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Name</label>
                                                        <input name="dzName" type="text" required className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Email Address</label>
                                                        <input name="dzEmail" type="email" className="form-control" required placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Phone</label>
                                                        <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-7 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Message...</label>
                                                        <textarea name="dzMessage" rows="4" className="form-control" required placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                <button name="submit" type="submit" value="Submit" className="site-button outline outline-2 radius-xl button-md m-t10">Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Contact Us End --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

          
            </>
        )
    }
}
export default  Homepage;