export const languages = {
    english: {
        "Platform Entry": "Platform Entry",
        "We want to challenge the new way of working": "We want to challenge the new way of working",
        "We create a specialized Methodology that is inserted in our all-in-one Multipurpose Platform": "We create a specialized Methodology that is inserted in our all-in-one Multipurpose Platform",
        "We seek to generate a more human hybrid work experience":"We seek to generate a more human hybrid work experience",
        "Watch Video": "Watch Video",
        "We created a specialized Methodology that is inserted in our all-in-one Multipurpose Platform.We seek to generate a more human hybrid work experience":"We created a specialized Methodology that is inserted in our all-in-one Multipurpose Platform.We seek to generate a more human hybrid work experience",
        // our way  tab
        "Our Why": "Our Why",
        "We want to humanize the way of doing deep work": "We want to humanize the way of doing deep work",
        "An adequate work methodology allows any organization to adapt better and its members to grow with it.We believe that it is possible to align the organization's strategy to face challenges collaboratively":"An adequate work methodology allows any organization to adapt better and its members to grow with it.We believe that it is possible to align the organization's strategy to face challenges collaboratively",
        "We create a specialized methodology to support organizations": "We create a specialized methodology to support organizations",
        "With our methodology we will challenge the current way of working from the office collaboratively with the remote environment We deliver our support with videos, concrete explanations and specific questions for each role and at opportune times We want to generate competencies to improve the collaborative performance of the teams": "With our methodology we will challenge the current way of working from the office collaboratively with the remote environment We deliver our support with videos, concrete explanations and specific questions for each role and at opportune times We want to generate competencies to improve the collaborative performance of the teams",
        // out platform tab
        "Our Platform": "Our Platform",
        "We developed a web platform that integrates our methodology": "We developed a web platform that integrates our methodology",
        "Our methodology aims to challenge the current way of working collaboratively.We want to generate competencies in people to improve the collaborative performance of teams": "Our methodology aims to challenge the current way of working collaboratively.We want to generate competencies in people to improve the collaborative performance of teams",
       // Development tab
        "MÖ Development ": "MÖ Development ",
        "MÖ is a Chilean venture and we are still in development": "MÖ is a Chilean venture and we are still in development",
        "We take the challenge we are embarking on very seriously and we would like your support.Within the platform you will have a space to send us your comments with ideas or possible improvements We want to reach the world with a different, disruptive and efficient way of working... to improve everyone's quality of life":"We take the challenge we are embarking on very seriously and we would like your support.Within the platform you will have a space to send us your comments with ideas or possible improvements We want to reach the world with a different, disruptive and efficient way of working... to improve everyone's quality of life",
        // in simple
        "Our functionalities":"Our functionalities",
        "collaborative virtual room": "collaborative virtual room",
        "Much more than making thousands of video calls, our proposal is to carry out the appropriate and prepared meetings in advance": "Much more than making thousands of video calls, our proposal is to carry out the appropriate and prepared meetings in advance",

        "tracking board": "tracking board",
        "We want to keep track of your responsibilities and ideas, so that the priority is to move forward with all the clear information":"We want to keep track of your responsibilities and ideas, so that the priority is to move forward with all the clear information",

        "Personal and group notes": "Personal and group notes",
        "The idea is to support each other collaboratively and continuously. We have multiple tools to promote it.":"The idea is to support each other collaboratively and continuously. We have multiple tools to promote it.",

        "Support material": "Support material",
        "Within the entire platform you will find specialized support material to make a difference on a daily basis":"Within the entire platform you will find specialized support material to make a difference on a daily basis",

        "Follow-up Log": "Follow-up Log",
        "Relevant meetings will be recorded, to support the monitoring of responsibilities and avoid confusion":"Relevant meetings will be recorded, to support the monitoring of responsibilities and avoid confusion",

        "Upstream Projects": "Upstream Projects",
        "Any user can request the creation of a short-range project that contributes to the development of the organization Any user can request the creation of a short-range project that contributes to the development of the organization": "Any user can request the creation of a short-range project that contributes to the development of the organization Any user can request the creation of a short-range project that contributes to the development of the organization",

        "Document Manager":"Document Manager",
        "You can store all project-relevant documents in one space":"You can store all project-relevant documents in one space",

        "And Much More": "And Much More",
        "We are developing new functionalities and modules to improve the user experience and facilitate the work of teams": "We are developing new functionalities and modules to improve the user experience and facilitate the work of teams",
        // faqs
        "What is Method Ö?": "What is Method Ö? ",

        "MÖ is a service for organizations that wish to enhance hybrid or full remote work. We deliver a methodology specialized in distributed work and access to our": "MÖ is a service for organizations that wish to enhance hybrid or full remote work. We deliver a methodology specialized in distributed work and access to our",

        "Our methodology specializes in hybrid work":"Our methodology specializes in hybrid work", 
        "The transition of the office is here to stay and flexibility for employees is increasingly valued.Our work methodology focuses on facilitating hybrid work, generating skills in teams and promoting work values to take care of productivity from any physical place.We seek to generate the synergy of the office but from anywhere":"The transition of the office is here to stay and flexibility for employees is increasingly valued.Our work methodology focuses on facilitating hybrid work, generating skills in teams and promoting work values to take care of productivity from any physical place.We seek to generate the synergy of the office but from anywhere",
        "How do we differ from traditional platforms?": "How do we differ from traditional platforms?",
        "Our focus is on the employees of each organization, our purpose is to support them to grow and apprehend skills that facilitate their work.  The methodology and platform we have developed fulfills our purpose.":"Our focus is on the employees of each organization, our purpose is to support them to grow and apprehend skills that facilitate their work.  The methodology and platform we have developed fulfills our purpose.",

        "Who is this solution for?": "Who is this solution for?",
        "Any company or organization that wants to promote hybrid or full remote work within their work teams. Our platform focuses on all executable responsibilities without the need to be in person and that it makes sense to apply a collaborative follow-up for the progress of the organization.":"Any company or organization that wants to promote hybrid or full remote work within their work teams. Our platform focuses on all executable responsibilities without the need to be in person and that it makes sense to apply a collaborative follow-up for the progress of the organization.",
        "How will we make a real impact on organizations?": "How will we make a real impact on organizations?",
        "Within our methodology we deliver supporting content, different strategies to face the challenges and different key questions (according to the times and maturity of each project)": "Within our methodology we deliver supporting content, different strategies to face the challenges and different key questions (according to the times and maturity of each project)",
        // contact us
        "contact us": "contact us",
        "Your name": "Your name",
        "Your email address": "Your email address",
        "Phone no": "Phone no",
        "Your Message...": "Your Message...",
        "Aplique ahora": "Aplique ahora",
        // footer
        "Contact us": "Contact us",
        "Our Way": "Our Way",
        "Functionality": "Functionality",
        "In  simple": "In  simple",
        "We invite you to talk and validate if we can help them":"We invite you to talk and validate if we can help them",
    },
    spanish: {

        "We want to challenge the new way of working": "Queremos desafiar la nueva forma de trabajar",
        "We create a specialized Methodology that is inserted in our all-in-one Multipurpose Platform":"Creamos una Metodología especializada que está inserta en nuestra Plataforma Multipropósito todo en uno",
      "We seek to generate a more human hybrid work experience":"Buscamos generar una experiencia de trabajo híbrido más humana",
        "Platform Entry": "Ingreso plataforma",
        "Watch Video": "Ver video",
        "We have created a specialized Methodology that is inserted in our all-in-one Multipurpose Platform.We seek to generate a more human hybrid work experience":"Creamos una Metodología especializada que está inserta en nuestra Plataforma Multipropósito todo en uno.Buscamos generar una experiencia de trabajo híbrido más humana",
        // our way tab
        "Our Why": "Nuestro porqué",
        "We want to humanize the way of doing deep work": "Queremos humanizar la forma de hacer el trabajo profundo",
        "An adequate work methodology allows any organization to adapt better and its members to grow with it.We believe that it is possible to align the organization's strategy to face challenges collaboratively":"Una adecuada metodología de trabajo permite que cualquier organización se adapte mejor y sus miembros crezcan con ella.Creemos que es posible alinear la estrategia de la organización para afrontar retos de forma colaborativa",        
        // how do we do it
        "How Do We Do It": "Cómo lo hacemos",
        "We create a specialized methodology to support organizations": "Creamos una metodología especializada para apoyar a las organizaciones",
        "Our methodology aims to challenge the current way of working collaboratively.We want to generate competencies in people to improve the collaborative performance of teams": "Nuestra metodología pretende desafiar la forma actual de trabajar colaborativamente.Queremos generar competencias en las personas para mejorar el desempeño colaborativo de los equipos",
        // out platform tab
        "Our Platform": "Nuestra Plataforma",
        "We developed a web platform that integrates our methodology": "Desarrollamos una plataforma web que integra nuestra metodología",
        "Our web platform is aligned with the methodology to get the most out of the work teams, challenge them and support them to improve.Within each space you will find an explanatory video and a description to take advantage of all the functionalities and features of our platform":"Nuestra plataforma web se alinea con la metodología para sacar el máximo provecho de los equipos de trabajo, desafiarlos y apoyarlos a mejorar.Dentro de cada espacio encontrarás un video explicativo y una descripción para aprovechar todas las funcionalidades y características de nuestra plataforma",
      
        // "Our platform aligns with the methodology to get the most out of the teams, challenge them and support them to improve Within each space you will find an explanatory video and a description to take advantage of all the functionalities and features of our platform": "Nuestra plataforma se alinea con la metodología para sacar el máximo provecho de los equipos, desafiarlos y apoyarlos a mejorar Dentro de cada espacio encontrarás un video explicativo y una descripción para aprovechar todas las funcionalidades y características de nuestra plataforma",
        // // Development
        "MÖ Development ": "Nosotros",
        "MÖ is a Chilean venture and we are still in development": "MÖ es un emprendimiento chileno y aún estamos en desarrollo",
        "We take the challenge we are embarking on very seriously and we would like your support.Within the platform you will have a space to send us your comments with ideas or possible improvements We want to reach the world with a different, disruptive and efficient way of working... to improve everyone's quality of life":"Nos tomamos muy en serio el reto en el que nos embarcamos y nos gustaría contar con tu apoyo.Dentro de la plataforma tendrás un espacio para enviarnos tus comentarios con ideas o posibles mejoras Queremos llegar al mundo con una forma diferente, disruptiva y eficiente forma de trabajar... para mejorar la calidad de vida de todos",
        // in simple
         "Our functionalities":"Nuestras funcionalidades",
        "collaborative virtual room": "Sala virtual colaborativa",
        "Much more than making thousands of video calls, our proposal is to carry out the appropriate and prepared meetings in advance": "Mucho más que hacer miles de videollamadas, nuestra propuesta es realizar las reuniones oportunas y preparadas con antelación",

        "tracking board": "Tablero de seguimiento",
         "We want to keep track of your responsibilities and ideas, so that the priority is to move forward with all the clear information":"Queremos llevar registro de tus responsabilidades e ideas, para que la prioridad sea avanzar con toda la información clara",

        "Personal and group notes": "Notas personales y grupales",
        "The idea is to support each other collaboratively and continuously. We have multiple tools to promote it.": "La idea es prestarnos apoyo colaborativamente y de manera continua. Disponemos de múltiples herramientas para fomentarlo",


        "Support material": "Material de apoyo",
        "Within the entire platform you will find specialized support material to make a difference on a daily basis": "Dentro de toda la plataforma encontrás material de apoyo especializado para marcar la diferencia diariamente",

        "Follow-up log": "Bitácora de seguimiento",
        "Relevant meetings will be recorded, to support the monitoring of responsibilities and avoid confusion": "Las reuniones relevantes quedaran registradas, para apoyar el seguimiento de responsabilidades y evitar confusiones",
        
        "Bottom up projects": "Proyectos ascendentes",
        "Any user can request the creation of a short-range project that contributes to the development of the organization": "Todo usuario puede solicitar la creación un proyecto de corto alcance pero que aporte al desarrollo de la organización",

        "document manager":"Gestor de documentos",
        "You can store all project-relevant documents in one space": "Puedes almacenar todos los documentos relevantes para el proyecto en un solo espacio",

        "And much more": "Y mucho más",
        "We are developing new functionalities and modules to improve the user experience and facilitate the work of teams": "Estamos desarrollando nuevas funcionalidades y módulos para mejorar la experiencia de uso y facilitar el trabajo de los equipos",

        // faq
        "What is Method Ö?":"¿Qué es Método Ö?",

        "MÖ is a service for organizations that wish to promote hybrid or fully remote work. We deliver a specialized methodology in distributed work and access to our all-in-one platform": "MÖ es un servicio para organizaciones que deseen potenciar el trabajo hibrido o full remoto. Entregamos una metodología especializada en el trabajo distribuido y acceso a nuestra plataforma todo en uno",

        "Our methodology specializes in hybrid work": "Nuestra metodología se especializa en el trabajo hibrido",
        "The transition of the office is here to stay and flexibility for employees is increasingly valued.Our work methodology focuses on facilitating hybrid work, generating skills in teams and promoting work values to take care of productivity from any physical place.We seek to generate the synergy of the office but from anywhere":"La transición de la oficina llegó para quedarse y cada vez se valora más la flexibilidad para los empleados. Nuestra metodología de trabajo se enfoca en facilitar el trabajo híbrido, generando habilidades en los equipos y fomentando los valores laborales para cuidar la productividad desde cualquier lugar físico. Buscamos generar la sinergia de la oficina pero desde cualquier lugar",

        "How do we differ from traditional platforms?": "Cómo nos diferenciamos de las plataformas tradicionales? ",
        "Our focus is on the employees of each organization, our purpose is to support them to grow and apprehend skills that facilitate their work.  The methodology and platform we have developed fulfills our purpose.": "Nuestro foco está en los colaboradores de cada organización, nuestro propósito es apoyarlos para que crezcan y adquieran habilidades que faciliten su trabajo.Nuestra metodología y plataforma entrega múltiples herramientas y contenido valioso para el desarrollo de muchos aspectos relevantes para el éxito",

        "Who is this solution for?": "¿Para quién es esta solución?",
        "Any company or organization that wants to promote hybrid or full remote work within their work teams. Our platform focuses on all executable responsibilities without the need to be in person and that it makes sense to apply a collaborative follow-up for the progress of the organization.":"Cualquier empresa u organización que quiera potenciar el trabajo híbrido o full remoto dentro de sus equipos de trabajo. Nuestra plataforma se enfoca en todas las responsabilidades ejecutables sin necesidad de presencialidad y que tiene sentido aplicar un seguimiento colaborativo para el avance de la organización.",

        "How will we make a real impact on organizations?": "Cómo lograremos un impacto real en las organizaciones?",
        "Within our methodology we deliver supporting content, different strategies to face the challenges and different key questions (according to the times and maturity of each project)": "Dentro de nuestra metodología entregamos contenidos de apoyo, distintas estrategias para afrontar los retos y distintas preguntas clave (según los tiempos y madurez de cada proyecto)",

        // contact us

        "contact us": "Contactanos",
        "Your name": "Nombre y apellido",
        "Your email address": "Su dirección de correo electrónico",

        "Phone no": "Telefono no",
        "Your Message...": "Tu mensaje...",
        "Submit Now": "Aplique ahora",
        // footer
        "Our Why": "Nuestro Porqué",
        "Functionality": "Funcionalidades       ",
        "In  simple": "En simple",

        "Contact us": "Contacta con nosotros",
        "We invite you to talk and validate if we can help them":"Te invitamos a conversar y validar si podemos ayudarlos"
    },
}