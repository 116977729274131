

import { languages } from "../config/languages";

const Text = (props) => {

    let selectedLanguage = "spanish";

    const {
        children
    } = props || {}

    const translated = languages[selectedLanguage][children]

    console.log("translated===>", translated)

    return (
        <>
            {translated}
        </>
    )
}

export default Text