import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import about4 from './../../images/about/pic4.jpg';
import about5 from './../../images/about/pic5.jpg';
import about6 from './../../images/about/pic6.jpg';
import about7 from './../../images/about/pic7.jpg';
import Text from '../../components/Text';
const Index4Tab = () => {
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}
	return (
		<>
			<div className="dez-tabs"  >
				<div className="m-auto text-center tab-market">
					<ul className="nav nav-tabs">
						<li>
							<Link to={"#"} className={classnames({ active: activeTab === '1' }) + ''} onClick={() => { toggle('1'); }}>
								<i className="ti-direction-alt"></i><span className="title-head"><Text>Our Why</Text></span>
							</Link>
						</li>
						<li>
							<Link to={"#"} className={classnames({ active: activeTab === '2' }) + ''} onClick={() => { toggle('2'); }}>
								<i className="ti-help-alt"></i><span className="title-head"><Text>How Do We Do It</Text></span>
							</Link>
						</li>
						<li>
							<Link to={"#"} className={classnames({ active: activeTab === '3' }) + ''} onClick={() => { toggle('3'); }}>
								<i className="ti-desktop"></i><span className="title-head"><Text>Our Platform</Text></span>
							</Link>
						</li>
						<li>
							<Link to={"#"} className={classnames({ active: activeTab === '4' }) + ''} onClick={() => { toggle('4'); }}>
								<i className="ti-split-v-alt"></i><span className="title-head"><Text>MÖ Development </Text></span>
							</Link>
						</li>
					</ul>
				</div>
				<div className="tab-content m-t50 text-white">
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div id="web-design" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-8 m-b30">
										<div className="box">
											<h3 className="font-40">
												
												<Text>
												We want to humanize the way of doing deep work
												</Text>
											</h3>
											<p>
											<Text>An adequate work methodology allows any organization to adapt better and its members to grow with it.We believe that it is possible to align the organization's strategy to face challenges collaboratively</Text>
											</p>


										</div>
									</div>
									<div className="col-lg-4">
										<img src={about4} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="2">
							<div id="web-development" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={about5} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="box">
											<h3 className="font-40">

												<Text>
													We create a specialized methodology to support organizations
												</Text>
											</h3>
											<p>
												<Text>
												Our methodology aims to challenge the current way of working collaboratively.We want to generate competencies in people to improve the collaborative performance of teams</Text>
											</p>
											{/* <Link to={"#"} className="site-button radius-xl button-md">Read More</Link> */}
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="3">
							<div id="mobile-app" className="tab-pane active show fade">
								<div className="row">
									<div className="col-lg-8 m-b30">
										<div className="box">
											<h3 className="font-40 tabheading">
											<Text>
												We developed a web platform that integrates our methodology
												</Text>

											</h3>
											<p className='tabdescription'>
											<Text>
												Our web platform is aligned with the methodology to get the most out of the work teams, challenge them and support them to improve.Within each space you will find an explanatory video and a description to take advantage of all the functionalities and features of our platform</Text>
											
											{/* <Text>
													An adequate work methodology allows any organization to adapt better and its members to grow with it.We believe that it is possible to align the organization's strategy to face challenges collaboratively
											</Text> */}
												{/* */}
											</p>
											{/* <Link to={"#"} className="site-button radius-xl button-md">Read More</Link> */}
										</div>
									</div>
									<div className="col-lg-4">
										<img src={about6} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="4">
							<div id="internet-marketing" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={about7} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="box">
											<h3 className="font-40"><Text>MÖ is a Chilean venture and we are still in development</Text></h3>
											<p>
												<Text>We take the challenge we are embarking on very seriously and we would like your support.Within the platform you will have a space to send us your comments with ideas or possible improvements We want to reach the world with a different, disruptive and efficient way of working... to improve everyone's quality of life</Text>
											</p>
											{/* <Link to={"#"} className="site-button radius-xl button-md">Read More</Link> */}
										</div>
									</div>
								</div>
							</div>
						</TabPane>
					</TabContent>
				</div>
			</div>
		</>
	)
}

export default Index4Tab;