import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, } from 'react-bootstrap';
import Text from '../../components/Text';
const defaultAccordion = [
	{
		title: 'What is Method Ö?',
		Text:
			'MÖ is a service for organizations that wish to promote hybrid or fully remote work. We deliver a specialized methodology in distributed work and access to our all-in-one platform',
	},
	{
		title: 'Our methodology specializes in hybrid work',
		Text:
			'The transition of the office is here to stay and flexibility for employees is increasingly valued.Our work methodology focuses on facilitating hybrid work, generating skills in teams and promoting work values to take care of productivity from any physical place.We seek to generate the synergy of the office but from anywhere',
	},
	{
		title: 'How do we differ from traditional platforms?',
		Text:
			'Our focus is on the employees of each organization, our purpose is to support them to grow and apprehend skills that facilitate their work.  The methodology and platform we have developed fulfills our purpose.',
	},
	{
		title: 'Who is this solution for?',
		Text:
			'Any company or organization that wants to promote hybrid or full remote work within their work teams. Our platform focuses on all executable responsibilities without the need to be in person and that it makes sense to apply a collaborative follow-up for the progress of the organization.',
	},
	{
		title: 'How will we make a real impact on organizations?',
		Text:
			'Within our methodology we deliver supporting content, different strategies to face the challenges and different key questions (according to the times and maturity of each project)',
	},

]
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return (

		<Accordion className="accordion dlab-accordion faq-1 box-sort-in m-b30" defaultActiveKey="-1">
			{/* <Text> */}
			{defaultAccordion.map((d, i) => (
				<div className="panel">
					<div className="acod-head">
						<h6 className="acod-title">
							<Accordion.Toggle as={Link} variant="link"
								className={` ${activeDefault === i ? '' : 'collapsed'}`}
								onClick={() =>
									setActiveDefault(activeDefault === i ? -1 : i)
								} eventKey={`${i}`}>
								<Text>{d.title}</Text>
							</Accordion.Toggle>
						</h6>
					</div>

					<Accordion.Collapse eventKey={`${i}`} className="acod-body">
						<div className="acod-content">
							<Text>{d.Text}</Text>
						</div>
					</Accordion.Collapse>
				</div>
			))}
			{/* </Text> */}

		</Accordion>
	)
}
export { defaultAccordion };
export default AccordionBlog;