import React from 'react';

import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
import services3 from './../../../images/our-services/pic3.jpg';
import services4 from './../../../images/our-services/pic4.jpg';

import work1 from './../../../images/our-work/pic1.jpg';
import work2 from './../../../images/our-work/pic2.jpg';
import work3 from './../../../images/our-work/pic3.jpg';
import work4 from './../../../images/our-work/pic4.jpg';


const wraperBlog = [
	{ icon: <i className="ti-headphone-alt" />, title: 'Market forecast' },
	{ icon: <i className="ti-ruler-pencil" />, title: 'Most Versatile' },
	{ icon: <i className="ti-desktop" />, title: 'Workflow automation' },
];

const wraperBox2 = [
	{ icon: <i className="ti-desktop" />, title: 'Lifetime Updates', },
	{ icon: <i className="ti-ruler-pencil" />, title: 'Fast Supports', },
	{ icon: <i className="ti-palette" />, title: 'Interactive Elements', },
	{ icon: <i className="ti-file" />, title: 'Flexible Layout', },
];

const dlabBox = [
	{ icon: <i className="ti-ruler-pencil" />, title: 'Creative design', image: services1, },
	{ icon: <i className="ti-heart" />, title: 'Just Easy Way', image: services2, },
	{ icon: <i className="ti-brush-alt" />, title: 'Creative design', image: services3, },
	{ icon: <i className="ti-mouse-alt" />, title: 'Flexible Layout', image: services4, },
];

const wraperBox3 = [
	{ icon: <i className="flaticon-devices" />, title: 'Web Design Solutions' },
	{ icon: <i className="flaticon-pen" />, title: 'Web Development Solutions' },
	{ icon: <i className="flaticon-bar-chart" />, title: 'SEO/SMO Solutions' },
	{ icon: <i className="flaticon-file" />, title: 'Pre Customized Solutions' },
	{ icon: <i className="flaticon-notebook" />, title: 'Project Maintenance' },
	{ icon: <i className="flaticon-team" />, title: 'Social Networking Portal' },
];

const wraperBox4 = [
	{ icon: <i className="flaticon-devices" />, title: 'Custom Applications' },
	{ icon: <i className="flaticon-pen " />, title: 'Dynamic Website' },
	{ icon: <i className="flaticon-layers" />, title: 'CMS Development' },
	{ icon: <i className="flaticon-shop" />, title: 'PHP based E-commercet' },
	{ icon: <i className="flaticon-technology" />, title: 'CMS Development' },
	{ icon: <i className="flaticon-internet" />, title: 'Cloud (SaaS, PaaS)' },
];

const dlabBox2 = [
	{ icon: <i className="flaticon-diamond" />, title: 'Business', image: work1, },
	{ icon: <i className="flaticon-pen" />, title: 'Consulting', image: work2, },
	{ icon: <i className="flaticon-file" />, title: 'Finance', image: work3, },
	{ icon: <i className="flaticon-bar-chart" />, title: 'Growth', image: work4, },
];

const wraperBox5 = [
	{ icon: <i className="ti-check-box" />, title: 'Ensure Security', },
	{ icon: <i className="ti-user" />, title: 'Expert Team', },
	{ icon: <i className="ti-headphone-alt" />, title: '24/7 SUPPORT', },
];

const wraperBox6 = [
	{ icon: <i className="flaticon-bar-chart" />, title: 'Make it Simple', },
	{ icon: <i className="flaticon-trophy" />, title: 'Unique design', },
	{ icon: <i className="flaticon-devices" />, title: 'True Responsiveness', },
];
const wraperBox7 = [
	{ icon: <i className="flaticon-pen" />, title: 'Creative', },
	{ icon: <i className="flaticon-diamond" />, title: 'Unique design', },
	{ icon: <i className="flaticon-devices" />, title: 'Innovative', },
];

const wraperBox8 = [
	{
		icon1: <i className="flaticon-trophy" />,
		title1: 'collaborative virtual',
		description1: 'Much more than making thousands of video calls, our proposal is to carry out the appropriate and prepared meetings in advance	',

	},
	{
		icon2: <i class="ti-agenda "></i>,
		title2: 'Follow-up Log',
		description2: 'The relevant meetings will be recorded, to support the follow-up of responsibilities and avoid confusion	'
	},
	{
		icon3: <i className="flaticon-bar-chart" />,
		title3: 'tracking board	',
		description3: 'We want to keep track of your responsibilities and ideas, so that the priority is to move forward with all the clear information	',
	},

	{
		icon4: <i className="flaticon-devices" />,
		title4: 'Upstream Projects	',
		description4: 'Any user can request the creation of a short-range project that contributes to the development of the organization Any user can request the creation of a short-range project that contributes to the development of the organization	',


	},
	{
		icon5: <i class="ti-agenda "></i>,
		title5: 'Personal and group notes	',
		description5: ' The idea is to support us collaboratively and on an ongoing basis. We have multiple tools to promote it.	'
	},
	{
		icon6: <i className="flaticon-technology" />,
		title6: 'Document Manager',
		 description6: 'You can store all documents relevant to the project in a single space	'
	},
	{
		icon7: <i class="fa fa-comments"></i>,
		title7: 'Support material	',
		description7: 'Within the entire platform you will find specialized support material to make a difference daily	',
	},
	{

		icon8: <i className="flaticon-devices" />,
		title8: 'And Much More',
		description8: 'We are developing new functionalities and modules to improve the user experience and facilitate the work of the teams	'
	},




];



export {
	wraperBlog,
	wraperBox2,
	wraperBox3,
	wraperBox4,
	wraperBox5,
	wraperBox6,
	wraperBox7,
	wraperBox8,
	dlabBox,
	dlabBox2,
};