import React,{Component} from 'react';
import {Link} from 'react-router-dom';
// import AnchorLink from "react-anchor-link-smooth-scroll";
// import { HashLink } from 'react-router-hash-link';
import Text from '../../components/Text';
import AnchorLink from "react-anchor-link-smooth-scroll";
class HeaderMenu extends Component{
	render(){
		return(
			<>
			<nav>
			<ul className="nav navbar-nav">
					<li className="active">
					<AnchorLink href='#home'><Text>Our Why</Text></AnchorLink>
			     	</li>
					<li>
					<AnchorLink href='#feature'><Text>Functionality</Text></AnchorLink>
					</li>
					<li>
					<AnchorLink href='#service'><Text>In  simple</Text></AnchorLink>
					</li>
					<li>
					<AnchorLink href='#header'><Text>header</Text></AnchorLink>
					</li>
					<li>
					<AnchorLink href='#ContactUs'><Text>contact us</Text></AnchorLink>
					</li>
					
				</ul>

			</nav>
	
			</>
		)
	}
}
export default HeaderMenu;