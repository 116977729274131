import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo2 from './../../images/logo-black.png';
import AnchorLink from "react-anchor-link-smooth-scroll";
import Text from '../../components/Text';

class Footer5 extends Component {
    render() {
        return (
            <>
                <footer className="site-footer footer-white business-footer">
                    <div className="footer-top">
                        <div className="container" >
                            <div className="row">
                                <div className="col-6 col-lg-6 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                    {/* <div className="logo-header mostion"> */}
                                    <Link to={'./'} ><img src={logo2} alt="" 
                                    style={{width:"200px"}}/></Link>
                                    
                                {/* </div> */}
                                
                                <div> 
                                    
                                        {/* <p className="text-capitalize justify-text mt-3 pr-5 m-b20"> <Text>We invite you to talk and validate if we can help them</Text></p> */}
                    
                                        
                                
                                    
                                    </div>
                                     
                                    </div>
                                </div>
                              
                                <div className="col-lg-6 col-md-6 col-sm-6 footer-col-4 ">
                                    <div className="widget">
                                    <div className="row  justify-content-end">
                                <div className="dlab-topbar-left">
                                    <ul className='list-inline'>
                                    <li><Link to={"https://www.linkedin.com/company/m%C3%A9todo-%C3%B6/about/"} className="site-button linkkedin circle mr-1 text-end" style={{backgroundColor:"#007BB6",justifyContent:"end"}}><i className="fa fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                              
                            </div>
                                        {/* <h5 className="m-b30 text-white">Subscribe To Our Newsletter</h5>
                                        <p className="text-capitalize m-b20">If you have any questions, you can contact with us so that we can give you a satisfying answer. Subscribe to our newsletter to get our latest products.</p>
                                        <div className="subscribe-form m-b20">
                                            <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="input-group">
                                                    <input name="dzEmail" required="required" className="form-control" placeholder="Your Email Id" type="email" />
                                                    <span className="input-group-btn">
                                                        <button name="submit" value="Submit" type="submit" className="site-button">Subscribe</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div> */}
                                  
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-12 '>
                            <ul className="list-inline m-a0 float-right mx-5">
                                      
                            <li className="active">
					<AnchorLink href='#home'  style={{fontWeight:"bold"}}><Text>Our Why</Text></AnchorLink>
			     	</li>
					<li>
					<AnchorLink href='#service' style={{fontWeight:"bold"}}><Text>Functionality</Text></AnchorLink>
					</li>
					<li>
					<AnchorLink href='#feature' style={{fontWeight:"bold"}}><Text>In  simple</Text></AnchorLink>
					</li>
					{/* <li>
					<AnchorLink href='#faqs'>FAQS</AnchorLink>
					</li> */}
					<li>
					<AnchorLink href='#ContactUs' style={{fontWeight:"bold"}}><Text>Contact us</Text></AnchorLink>
					</li>  
                              
                                 </ul>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 text-center "> <span>Copyright © 2022 Método Ö</span> </div>
                            
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer5;