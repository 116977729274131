import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Header6 from './../Layout/Header6';
import Footer5 from './../Layout/footer5';
import Footer from './../Layout/Footer1';
import Index4Tab from './../Element/Index4Tab';
import ReviewsCarousel from './../Element/ReviewsCarousel';
import ShortAccordions from '../Pages/ShortCode/ShortAccordions'
import { VideoPopup2 } from './../Element/VideoPopup';
import bnr2 from '../../images/background/bg1.png';
import axios from 'axios';

import {
    wraperBlog, wraperBox2, wraperBox3, wraperBox4,
    wraperBox5, wraperBox6, wraperBox7, wraperBox8,
    dlabBox, dlabBox2,
} from '../Pages/ShortCode/DataFile';
//Images..
import bgimg from './../../images/main-slider/slide6c.jpg';
import bg16 from './../../images/background/bg16.jpg';
import about1 from './../../images/about/pic9.jpg';
import bg1 from './../../images/background/bg1.jpg';
import gallery10 from './../../images/gallery/pic10.jpg';
import gallery11 from './../../images/gallery/pic11.jpg';
import gallery12 from './../../images/gallery/pic12.jpg';
import gallery13 from './../../images/gallery/pic13.jpg';
import gallery14 from './../../images/gallery/pic14.jpg';
import gallery15 from './../../images/gallery/pic15.jpg';
import gallery16 from './../../images/gallery/pic16.jpg';
import headerlogo from './../../images/headerlogo.png';
import Text from '../../components/Text';
import { T,LanguageList,Config } from "react-translator-component";
  Config.default = "en";

Config.list = {
  sp: {
    text: "español",
    
  },
  en: {
    text: "English",

  },
 
 
};

//Light Gallery on icon click 
const Iconimage = props => {
    const { openLightbox } = useLightbox()
    return (
        <Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link" >
            <i className="ti-fullscreen icon-bx-xs"></i>
        </Link>
    )
}

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
           phone_no:'',
          message: '',
          
        }
      }
      handleSubmit(e){
        e.preventDefault();
        axios({
          method: "POST",
          url:"https://projecto.metodoo.com/api/contact",
          data:  this.state
        }).then((response)=>{
          if (response.data.status === 'success') {
            alert("Contact added successfully.");
            this.resetForm()
          } else if (response.data.status === 'fail') {
            alert("Contact is not added.")
          }
        })
      }
      resetForm(){
        this.setState({ username: '', email: '', phone_no:'',message: ''})
      }
    componentDidMount() {
        var i = 0;

        // Placeholder Animation Start
        var inputSelector = document.querySelectorAll('input, textarea');

        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener('focus', function (event) {
                return this.parentElement.parentElement.classList.add("focused");
            });
        }


        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener('blur', function (event) {
                var inputValue = this.value;
                if (inputValue === '') {
                    this.parentElement.parentElement.classList.remove('filled');
                    this.parentElement.parentElement.classList.remove('focused');
                } else {
                    this.parentElement.parentElement.classList.add('filled');
                }
            });
        }
    }
  
    render() {
        return (
            <>
        
            
            {/* <LanguageList Language={this.state.language} />
                  <select
                    className="custom-language-list"
                    value={this.state.language}
                    onChange={e => this.setState({ language: e.target.value })}
                  >
                    {Object.keys(Config.list).map(key => (
                      <option key={key} value={key}>
                        {Config.list[key].text}
                      </option>
                    ))}
                  </select> */}
                  {/* <Translator> */}
            {/* <h6> */}
              {/* {T("this is english language and please as per project requirments its language change into spanish.")} */}
            {/* </h6> */}
            {/* </Translator> */}
                <Header6 />
                <div className="page-content bg-white rubik" id="header">

                    <div className="home-banner img-fluid git commit  bg-img-fix "
                     style={{ backgroundImage: "url(" + bgimg + ")",backgroundRepeat:"no-repeat", height:"800px",position:"relative", }}
                      >

                         <div className="home-bnr-inner" id="header">
                         <div className=" " >
                                <Link to={'./'} className="dez-page"><img src={headerlogo} alt="" style={{ width: "200px" ,}} /></Link>
                                <div className="home-bnr-btns">
                                    <VideoPopup2 />
                                </div>
                            </div> 
                        </div> 
                    </div>

                    {/* <!-- contact area --> */}
                    <div className="content-block">


                        <div className="gradient testimonial-curv-bx" >
                            <div className="container">
                                <div className="row py-5 my-5">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-9">
                                        <div className="section-head text-center">
                                            <h1 className="text-uppercase">
                                                <span className="font-weight-300">
                                                    {/* <Text>
                                                    We created a specialized Methodology that is inserted in our all-in-one Multipurpose Platform.We seek to generate a more human hybrid work experience.
                                                    </Text> */}
                                                </span>
                                                <br />
                                                <Text>
                                                We want to challenge the new way of working
                                                </Text>
                                            </h1>
                                            {/* <h5 > */}
                                            <p className="font-weight-400 text-gray-dark ">
                                                <Text>
                                                We create a specialized Methodology that is inserted in our all-in-one Multipurpose Platform</Text>
                                            <br/>
                                                <Text>We seek to generate a more human hybrid work experience</Text>
                                            </p>

                                            {/*                                          
                                                </h5> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--our services--> */}
                        <div  id="home" className='py-5 '>
                        <div   className='my-5 '>
                        <div   className='py-5'>
                        <div className="section-full content-inner-2 tab-bx br-top">
                            <div className="container">
                        
                                    <Index4Tab />
                             
                            </div>
                        </div>
                        </div>
                        </div>
                       </div>
                       
                     


                        {/* !----features ---! */}
                        <div id="feature" className='py-3'>
                        <div className="section-full bg-white content-inner" id="feature" style={{ backgroundImage: "url(" + bnr2 + ")" }}   >

<div className="container">
    <div className="section-content">
        <div className="row">
        <h1 className='text-upsercase font-wight-300 px-2'><Text>Our functionalities</Text></h1>
            {/* {wraperBox8.map((item, index) => ( */}
                <div className="col-12 col-lg-12 col-md-12 col-sm-6 d-flex">
                  
                    <div className="icon-bx-wraper">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i className="flaticon-trophy" /></Link>
                        </div>
                        <div className="icon-content ">
                            <h5 className="dlab-tilte"><Text>collaborative virtual room</Text></h5>
                            <p className="dlab-tilte"><Text>Much more than making thousands of video calls, our proposal is to carry out the appropriate and prepared meetings in advance</Text></p>

                        </div>
                     
                    </div>
                    <div className="icon-bx- mt-2 px-3">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"> <i class="ti-agenda "></i></Link>
                        </div>
                        <div className="icon-content ">
                            <h5 className="dlab-tilte mt-3"><Text>tracking board</Text></h5>
                            <p className="dlab-tilte"><Text>We want to keep track of your responsibilities and ideas, so that the priority is to move forward with all the clear information</Text></p>

                        </div>
                     
                    </div>
                    <div className="icon-bx-wraper">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i className="flaticon-bar-chart" /></Link>
                        </div>
                        <div className="icon-content ">
                            <h5 className="dlab-tilte"><Text>Personal and group notes</Text></h5>
                            <p className="dlab-tilte"> <Text>The idea is to support each other collaboratively and continuously. We have multiple tools to promote it.</Text></p>

                        </div>
                     
                    </div>
                    <div className="icon-bx-wraper px-3">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"> <i className="flaticon-devices" /></Link>
                        </div>
                        <div className="icon-content ">
                            <h5 className="dlab-tilte"><Text>Support material</Text></h5>
                            <p className="dlab-tilte"> <Text>Within the entire platform you will find specialized support material to make a difference on a daily basis</Text></p>

                        </div>
                     
                    </div>
                    {/* <div className="icon-bx-wraper ">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black">{item.icon2}</Link>
                        </div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte"><Text>{item.title2}</Text></h5>
                            <p className="dlab-tilte">{item.description2}</p>

                        </div>
                    </div>
                    <div className="icon-bx-wraper ">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black">{item.icon3}</Link>
                        </div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte"><Text>{item.title3}</Text></h5>
                            <p className="dlab-tilte">{item.description3}</p>

                        </div>
                    </div>
                    <div className="icon-bx-wraper ">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black">{item.icon4}</Link>
                        </div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte"><Text>{item.title4}</Text></h5>
                            <p className="dlab-tilte">{item.description}</p>

                        </div>
                    </div> */}
                </div>
                <div className="col-12 col-lg-12 col-md-12 col-sm-6 d-flex mt-2">
                    <div className="icon-bx-wraper mt-3">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i class="ti-agenda "></i></Link>
                        </div>
                        <div className="icon-content mt-2">
                            <h5 className="dlab-tilte"><Text>Follow-up log</Text></h5>
                            <p className="dlab-tilte"> <Text>Relevant meetings will be recorded, to support the monitoring of responsibilities and avoid confusion</Text></p>

                        </div>
                     
                    </div>
                    <div className="icon-bx-wraper px-3">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i className="flaticon-technology" /></Link>
                        </div>
                        <div className="icon-content  ">
                            <h5 className="dlab-tilte"><Text>Bottom up projects</Text></h5>
                            <p className="dlab-tilte"> <Text>Any user can request the creation of a short-range project that contributes to the development of the organization</Text></p>

                        </div>
                     
                    </div>
                    <div className="icon-bx-wraper ">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i class="fa fa-comments"></i></Link>
                        </div>
                        <div className="icon-content mt-2">
                            <h5 className="dlab-tilte"><Text>document manager</Text></h5>
                            <p className="dlab-tilte"><Text>You can store all project-relevant documents in one space</Text></p>
                        </div>
                     
                    </div>
                    <div className="icon-bx-wraper px-3">
                        <div className="icon-md text-black ">
                            <Link to={"#"} className="icon-cell text-black"><i className="flaticon-devices" /></Link>
                        </div>
                        <div className="icon-content ">
                            <h5 className="dlab-tilte"><Text>And much more</Text></h5>
                            <p className="dlab-tilte"> <Text>We are developing new functionalities and modules to improve the user experience and facilitate the work of teams</Text></p>

                        </div>
                     
                    </div>
          
                </div>
            {/* ))} */}

        </div>
    </div>
</div>
</div>
                        </div>
                 
                        {/*---- faqs ----*/}
                               <div id='service' className='py-5'>
                               <div> <ShortAccordions /></div>
                                    </div>
                        

                        {/* faqs end */}
                        {/* -----contact us------ */}
                        <div id="ContactUs" >
                        <div className="section-full content-inner-2 bg-white contact-form-bx " style={{ backgroundImage: "url(" + bg16 + ")", backgroundSize: "100%",}}>
                            <div className="container py-5" >
                                <div className="section-head text-center" >
                                    <h2 className="text-decoration-none">
                                        <span className="font-weight-300">Contact us
                                            {/* <Text></Text> */}
                                        </span>
                                    </h2>
                                </div>
                                <div className="dezPlaceAni">
                                    <div className="dzFormMsg"></div>
                                    <form  id="contact-form"  action=""  onSubmit={this.handleSubmit.bind(this)} method="POST" 
                                    >
                                        {/* <input type="hidden" value="Contact" name="dzToDo" /> */}
                                        <div className="row">
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>
                                                        <Text>Your name</Text></label>
                                                        {/* <input name="dzName" type="text" required className="form-control" placeholder="" /> */}
                                                        <input type="text" className="form-control" id="username" value={this.state.username} onChange={this.onUserNameChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label><Text>Your email address</Text></label>
                                                        {/* <input name="dzEmail" type="email" className="form-control" required placeholder="" /> */}
                                                        <input type="text" className="form-control" id="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label><Text>Phone no</Text></label>
                                                        {/* <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="" /> */}
                                                        <input type="text" className="form-control" id="phone_no" value={this.state. phone_no} onChange={this.onPhone_noChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-7 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label><Text>Your Message...</Text></label>
                                                        {/* <textarea name="dzMessage" rows="4" className="form-control" required placeholder=""></textarea> */}
                                                        <textarea type="text" className="form-control" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} >
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                <button name="submit" type="submit" value="Submit" className="site-button outline outline-2 radius-xl button-md m-t10"><Text>Submit Now</Text></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                        {/* <!-- Contact Us End --> */}
                    </div>

                </div>

                <Footer5 />
            </>
        )
    }
    onUserNameChange(event){
        this.setState({username: event.target.value})
       }
         onEmailChange(event) {
        	  this.setState({email: event.target.value})
          }
          onPhone_noChange(event) {
            this.setState({phone_no: event.target.value})
        }
          onMessageChange(event) {
        	  this.setState({message: event.target.value})
          }
}

export default Homepage;