import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from './../../images/logo.png';

// import headerlogo from './../../images/headerlogo.jpg';
import logo2 from './../../images/logo-black.png';
import HeaderMenu from './HeaderMenu';
import AnchorLink from "react-anchor-link-smooth-scroll";
// import '../../css/custom.css'
import Text from '../../components/Text';
class Header6 extends Component {

    componentDidMount() {
        // sidebar open/close

        var Navicon = document.querySelector('.navicon');
        var sidebarmenu = document.querySelector('.myNavbar ');

        function toggleFunc() {
            sidebarmenu.classList.toggle('show');
            Navicon.classList.toggle('open');
        }
        Navicon.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }


        function checkLi(current) {
            current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
                (current.parentElement !== el) ? el.classList.remove('open') : ''
            );

            setTimeout(() => {
                current.parentElement.classList.toggle('open');

            }, 100);
        }
    }
    // constructor(props) {
    //     super(props)
        

    //     this.state = {
    //          selectedLanguage 
    //         // english: '',
    //         // spanish: ''
    //     }
    //     changeLanguage = (e) => {
    //         this.setState(!selectedLanguage)
    //     }
     
        
    // }

    render() {
        // const { english, spanish } = this.state
        return (
            <>
                <header className="site-header header mo-left header-transparent" id="fix-header">
                    <div className="sticky-header main-bar-wraper navbar-expand-lg">
                        <div className="main-bar clearfix">
                            <div className="container clearfix">

                              
                            <div className="logo-header mostion">
                            <AnchorLink href='#header' className="dez-page"><img src={logo} alt="" style={{ height: "50px" }}  /><Text>header</Text></AnchorLink>
                                    {/* <Link to={'./'} className="dez-page"> <h4 className=" text-white  font-29">Método Ö</h4></Link> */}
                                    {/* <Link to={'./'} /></Link> */}
                                </div>
                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>

                                </button>

                                <div className="extra-nav">
                                    <div className="extra-cell" >
                                        {/* <Link to={'./contact'} >
                                            Platform Entry</Link> */}
                                            	{/* <div className="form-group">
						
						 <select className="form-control">
								<option value={english}
                                 onChange={this.changeLanguage()} >english</option>
								<option value={spanish} 
                                onChange={this.changeLanguage()} >spanish</option>
							
							</select> 
						</div> */}
                                        <div className="dez-page site-button   btn-homepage6" style={{width:"100px !important"}} >
                                            <span><Text>Platform Entry</Text></span>
                                        </div>
                                    </div>

                                </div>

                                <div className="header-nav navbar-collapse collapse myNavbar justify-content-end" >

                                    <div className="logo-header mostion d-md-block d-lg-none"  style={{height:"10px"}}>
                                        <Link to={'./'} className="dez-page"><img src={logo2} alt="" /></Link>
                                    </div>

                                    {/*  Header Menu Contents  */}
                                    <HeaderMenu />
                                    {/*  Header Menu Contents End */}
                                </div>

                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
export default Header6;